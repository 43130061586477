import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

//使用VueRouter插件
Vue.use(VueRouter)

// 创建路由对应的view
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/About/:id',
        name: 'About',
        component: () => import('../views/About.vue')
    },
    {
        path: '/Edit/:id',
        name: 'Edit',
        component: () => import('../views/Edit.vue')
    },
    {
        path: '/Add/:id',
        name: 'Add',
        component: () => import('../views/Add.vue')
    },
    {
        path: '/NoLogin',
        name: 'NoLogin',
        component: () => import('../views/NoLogin.vue')
    }
]

// 创建VueRouter实例
const router = new VueRouter({
    //mode: "history",
    mode: "hash",
    routes
})

//main.js中引用的就是这个router
export default router
