export default {
    "users_add": "/users/add",
    "users_find": "/v3/weather/weatherInfo",
    "users_update": "/users/update",
    "users_delete": "/users/delete",

    "home_info": "/api/AnlHome/info",
    "home_onbuildchange":"/api/AnlHome/onbuilderchange",
    "add_info":"/api/AnlAdd/info",
    "add_info_save":"/api/AnlAdd/build",
    "detail_info":"/api/AnlDetail/info",
    "edit_info":"/api/AnlEdit/build",
    "home_info_more": "/api/AnlHome/more",
    "home_info_auth": "/api/AnlHome/logo",

}