import axios from "axios"
import api from "./api"
import { Message, Loading } from 'element-ui'

axios.defaults.timeout = 10000;//设置超时时间，单位毫秒
axios.defaults.retry = 3; //设置全局请求次数
axios.defaults.retryDelay = 1000;//设置全局请求间隙

//创建axios实例对象
let instance = axios.create({
    baseURL:"https://sms.hmcvip.com", //"/api",//"https://sms.hmcvip.com", //服务器地址
    timeout: 10000 //默认超时时长
})
let loadingInstance = null //这里是loading
//请求拦截器
instance.interceptors.request.use(config => {
    //此处编写请求拦截的代码，一般用于弹出加载窗口
    console.log("正在请求……")
    console.log('请求参数：', config)
    loadingInstance = Loading.service({
        lock: true,
        text: 'loading...'
    })
    return config
}, err => {
    console.error("请求失败", err)
    let msg = error.Message !== undefined ? error.Message : ''
    Message({
        message: '网络错误' + msg,
        type: 'error',
        duration: 3 * 1000
    })
    loadingInstance.close()
    return Promise.reject(error)

})

//响应拦截器
instance.interceptors.response.use(res => {
    //此处对响应数据做处理
    loadingInstance.close()
    console.log("请求成功！")
    console.log('返回结果：', res)
    return res //该返回对象会传到请求方法的响应对象中
}, err => {
    // 响应错误处理
    console.log("响应失败！", err)
    Message({
        message: '网络错误' + msg,
        type: 'error',
        duration: 3 * 1000
    })
    loadingInstance.close()
    return Promise.reject(error)
    // return Promise.reject(err);
})

//封装axios请求方法，参数为配置对象
//option = {method,url,params} method为请求方法，url为请求接口，params为请求参数
async function http(option = {}) {
    let result = null
    if (option.method === "get" || option.method === "delete") {
        //处理get、delete请求
        await instance[option.method](
            api[option.url],
            { params: option.params }
        ).then(res => {
            result = res.data
        }).catch(err => {
            result = err
        })
    } else if (option.method === "post" || option.method === "put") {
        //处理post、put请求
        await instance[option.method](
            api[option.url],
            option.params
        ).then(res => {
            result = res.data
        }).catch(err => {
            result = err
        })
    }

    return result
}

export default http