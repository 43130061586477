import Vue from 'vue'
import App from './App.vue'

// 引入组件脚本
import Button from 'vant/es/button/index';
import { Field, CellGroup, NavBar,Cell,Empty } from 'vant';
import { ActionSheet, Col, Row, Checkbox, CheckboxGroup, Divider, Tag, Search, Swipe, SwipeItem, Tab, Tabs,List,PullRefresh ,Loading} from 'vant';
import Toast from 'vant/es/toast/index';

import iView from 'iview';
// 引入组件样式
// 若组件没有样式文件，则无须引入
import 'vant/lib/index.css';

import 'iview/dist/styles/iview.css';    // 使用 CSS

// import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'

import AllIosIcon from "vue-ionicons/dist/ionicons-ios.js";

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

import router from './router'

//import apiFun from "@/api/api.js";
import http from "./http"

import vConsole from 'vconsole'
Vue.prototype.$vConsole= new vConsole()

Vue.use(Button).use(ActionSheet).use(Field).use(CellGroup).use(Toast).use(Col).use(Divider).use(Row).use(Checkbox).use(CheckboxGroup).use(Tag).use(Search).use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(NavBar).use(List).use(PullRefresh).use(Loading).use(Cell).use(Empty);


Vue.use(AllIosIcon)
Vue.use(iView)
Vue.use(ViewUI)
Vue.use(router)

Vue.config.productionTip = false
Vue.prototype.$http = http
//Vue.prototype.$apiFun = apiFun;//请求接口api
// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
//   next()
// })

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
